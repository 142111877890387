import React from "react"
import {Link} from "gatsby"
import {useStaticQuery, graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import {slugify} from "../helpers"
// import LeadForm from "../components/leadform";

const Blog = (props) => {
    const data = useStaticQuery(graphql`
    query {
      allContentfulArticles {
        edges {
          node {
            body {
              body
            }
            id
            title
            intro
            cover {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

    const articles = data.allContentfulArticles.edges;
    return (
        <Layout>
            <SEO title="Articles" description={`Web application development and business digital transformation blog. A dive on best practices, web security, application performance and next gen digital experiences.`}/>
          <div className={'relative'}><div className={'hidden xxl:block purple-rotated-div'}/></div>
          <div className={'relative'}><div className={'hidden xxl:block gray-rotated-div'}/></div>
          {/*<div className={'hidden lg:block'}>*/}
          {/*  <LeadForm/>*/}
          {/*</div>*/}
          {/*<div className={'block lg:hidden'}>*/}
          {/*  <MobileLeadingForm/>*/}
          {/*</div>*/}
            <div className="mx-auto container font-sans flex flex-col items-center justify-around lg:py-4">
                <div className="max-w-3xl">
                    <h1 className={`text-xl font-bold text-purple-800 mb-4 text-left px-8`}>Blog</h1>
                    {articles.map(({node: article}) => {
                        return <div className={'flex items-start p-2 flex flex-col md:flex-row justify-start text-navy-600 lg:mb-12 mb-4 w-full lg:mx-4'} key={article.id}>
                            <div className={'w-full md:w-1/4 px-2'}>
                                <Img fluid={article.cover.localFile.childImageSharp.fluid} className="w-full mb-2 md:mb-0 mx-auto"/>
                            </div>
                            <div className={'w-full md:w-3/4 px-4 -mt-1'}>
                                <Link to={'/blog/' + slugify(article.title)} aria-label={article.name + ' Nunuma'}>
                                    <h2 className="font-bold text-xl text-purple-800 leading-tight mb-2">{article.title}</h2>
                                </Link>
                                <p className="text-copy mb-1">{article.intro}</p>
                                <Link to={'/blog/' + slugify(article.title)} aria-label={article.name + ' Nunuma'} className={'font-bold text-sm text-purple-800'}>Continue reading</Link>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </Layout>
    )
}

export default Blog